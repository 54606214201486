import { Link } from "@StarberryUtils";
import * as React from "react"
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router"

import AgentImg from "../../images/member.jpg"
import Dacha from "../../images/dacha.png"

import { GetURL } from "../common/site/functions";
import "./AreaGuideSidebar.scss"

import { PhoneIconBlue, PhoneIconGrey, EnvelopeIconBlue, EnvelopeIconGrey, WhatsAppIconBlue, WhatsAppIconGreen } from "../Icons/Icons";

const AreaGuideSidebar = (props) => {
	//console.log(props,"propsss")
	const location = useLocation();
	var location_link = process.env.GATSBY_SITE_URL+location.pathname

	var whatsapp_no = props?.site?.Phone ? props.site.Phone.replace(/ /g,'').replace(/\+/g,'') : "9710505313003"

	var whatsapp_link = `https://wa.me/${whatsapp_no}?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	if(location.search.indexOf("utm_source") > 0){
		whatsapp_link = `https://wa.me/${whatsapp_no}?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	}

	
	return (
		<React.Fragment>
			<div className="area-guide-sidebar static-page-sidebar left-sticky">

				{HTMLReactParser(props.content.replace(/<br\s*\/?>/gi, ''))}

				<div className=" d-xl-flex row-btn-group area-guide-sidebar-btn-group">
					<Link to={`/contact`} className="btn btn-primary">Contact Dacha</Link>
					<Link to={`/about-dacha/our-team`} className="btn btn-outline btn-outline-dark">Meet our team</Link>
				</div>
				{/* {
					props.peoples && props.peoples.map((people, i) => {
						return (<div className="agent-info" key={i}>
							{people.Tile_Image &&
								<div className="agent-pic">
									<img loading="lazy" src={people.Tile_Image.url} alt={`${people.Name} ${people.Designation} - Dacha`} />
								</div>
							}
							<div className="agent-info-details">
								<Link href={`/about-dacha/our-team/${people.URL}`}><strong className="agent-name">{people.Name}</strong></Link>
								<span className="agent-designation">{people.Designation}</span>
								{people.Phone &&
									<Link href={`tel:${people.Phone}`} className="agent-number"><i class="icon-people-phone"></i>{people.Phone}</Link>
								}
							</div>
						</div>)
					})
				} */}
				{
					(props.site.Email || props.site.Phone) &&
					// <div className="agent-info contact-info">
					// 	<div className="agent-pic">
					// 		<img loading="lazy" src={Dacha} alt={`Dacha Logo`} />
					// 	</div>
					// 	<div className="agent-info-details"> 
					// 		{props.site.Phone ?
					// 			<Link href={`tel:${props.site.Phone}`} className="agent-number"><i class="icon-people-phone"></i>{props.site.Phone}</Link>
					// 			:
					// 			<Link href={`tel:+971 4 423 2006`} className="agent-number"><i class="icon-people-phone"></i>+971 4 423 2006</Link>
					// 		}
					// 		<div className="d-flex">
					// 			<Link to="/contact/message-us" className="agent-number"><i class="icon-people-mail"></i>Email</Link>
					// 			{props.site.Phone ?
					// 				<a href={whatsapp_link} className="agent-number agent-whatsapp" target="_blank"><i class="whatsapp-icon"></i> WhatsApp</a>
					// 				:
					// 				<a href={whatsapp_link} className="agent-number agent-whatsapp" target="_blank"><i class="whatsapp-icon"></i> WhatsApp</a>
					// 			}
					// 		</div>
					// 	</div>
					// </div>
					<div className="agent-info">
					<div className="agent-pic default-logo">
						<img src={Dacha} alt="Dacha Logo" />
					</div>
					<div className="agent-data">
						<div className="default-contact-phone">
							<Link href={`tel:${props.site.Phone}`} className="text-sm">
								<span><PhoneIconGrey className="icon-contact-info" /></span>
								<span>{props.site.Phone}</span>
							</Link>
						</div>
						<div className="default-contact">
							<Link to="/contact/message-us" className="text-sm contact-mail">
								<span><EnvelopeIconGrey className="icon-contact-info" /></span><span>Email</span>
							</Link>
							<Link href={whatsapp_link} className="text-sm" target="_blank">
								<span><WhatsAppIconGreen className="icon-contact-info" /></span><span>WhatsApp</span>
							</Link>
						</div>
					</div>
				</div>
				}
				{/* {props.site.Available_Timings &&
					<div className="available-timing">
						{HTMLReactParser(props.site.Available_Timings.replace(/<br\s*\/?>/gi, ''))}
					</div>
				} */}
				{props?.open_hours &&
						<div className="open-timing text-sm">
							{HTMLReactParser(props?.open_hours.replace(/<br\s*\/?>/gi, ''))}
						</div>
			    }

			</div>
		</React.Fragment>
	)
}

export default AreaGuideSidebar